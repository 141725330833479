/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef, useEffect } from "react";
import { useGLTF, PerspectiveCamera, useAnimations } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    Cube_1: THREE.Mesh;
    Cube_2: THREE.Mesh;
    Cube_3: THREE.Mesh;
    Cube_4: THREE.Mesh;
    Cube_5: THREE.Mesh;
    Cube_6: THREE.Mesh;
    Cube_7: THREE.Mesh;
    Cube_8: THREE.Mesh;
    Cube_9: THREE.Mesh;
    Cube_10: THREE.Mesh;
    Cube_0: THREE.Mesh;
  };
  materials: {};
};

type ActionName = "animation_0";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF("/jump.gltf") as GLTFResult;
  const { actions } = useAnimations<GLTFActions>(animations, group);
  useEffect(()=>{
    actions.animation_0.play()
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <group name="Null" position={[-112.35, -121.26, 0]}>
          <group name="Null_1" position={[112.23, 120.77, 0]}>
            <group name="Null_2">
              <group name="Cloner">
                <mesh
                  name="Cube_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_1.geometry}
                  material={nodes.Cube_1.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_2"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_2.geometry}
                  material={nodes.Cube_2.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_3"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_3.geometry}
                  material={nodes.Cube_3.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_4"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_4.geometry}
                  material={nodes.Cube_4.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_5"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_5.geometry}
                  material={nodes.Cube_5.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_6"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_6.geometry}
                  material={nodes.Cube_6.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_7"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_7.geometry}
                  material={nodes.Cube_7.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_8"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_8.geometry}
                  material={nodes.Cube_8.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_9"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_9.geometry}
                  material={nodes.Cube_9.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_10"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_10.geometry}
                  material={nodes.Cube_10.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
                <mesh
                  name="Cube_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Cube_0.geometry}
                  material={nodes.Cube_0.material}
                  > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              </group>
            </group>
          </group>
        </group>
        <PerspectiveCamera
          name="Camera"
          makeDefault={true}
          far={10000000000}
          near={0.01}
          fov={30}
          position={[0, 0, -527]}
          rotation={[Math.PI, 0, -Math.PI]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/jump.gltf");