import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link as RouterLink, useStaticQuery, graphql, PageProps } from "gatsby";
import { MetaData } from "../components/common/meta";
import Hero from "../components/Index/Hero";
import Investments from "../components/Index/Investments";
import Blog from "../components/Index/Blog";
import sideBrand2 from "../images/pitLine2.svg";
import simpleLine2 from "../images/simpleLine2.svg";
 
import VideoHp from "../components/Index/VideoHp";
import Projects from "../components/Index/Projects";
import Arrow from "../images/arrow-right.inline.svg";
import bg from "../images/index/bg.svg";

export const cardIndex = {
  position: "absolute",
  left: 0,
  top: 0,
  backgroundColor: "#1CE7C2",
  color: "#0D2352",
  width: 92,
  height: 40,
  textAlign: "center",
  lineHeight: "40px",
};

const IndexPage = ({ location }: { location: PageProps }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allGhostPost(limit: 10) {
        edges {
          node {
            slug
            title
            feature_image
            published_at
            reading_time
            excerpt
            tags {
              slug
              name
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <MetaData title="" location={location} type="website" />
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "150px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          zIndex: -1,
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />

      <Hero /> 
      <Projects />



      <StaticImage
        src="../images/index/lines.svg"
        alt=""
        placeholder="blurred"
        style={{
          position: "absolute",
          right: "0",
          bottom: "80px",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default IndexPage;
