/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef, useEffect } from "react";
import { useGLTF, PerspectiveCamera, useAnimations } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    WireCube_1: THREE.Mesh;
    WireCube_2: THREE.Mesh;
    WireCube_3: THREE.Mesh;
    WireCube_4: THREE.Mesh;
    WireCube_5: THREE.Mesh;
    WireCube_6: THREE.Mesh;
    WireCube_7: THREE.Mesh;
    WireCube_8: THREE.Mesh;
    WireCube_9: THREE.Mesh;
    WireCube_10: THREE.Mesh;
    WireCube_0: THREE.Mesh;
  };
  materials: {};
};

type ActionName = "animation_0";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export default function Model2(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF("/jump2.gltf") as GLTFResult;
  const { actions } = useAnimations<GLTFActions>(animations, group);

  useEffect(()=>{
    actions.animation_0.play()
  })
  return (
    <group ref={group} {...props} dispose={null}>
    <group>
      <group name="Null" position={[-112.35, -121.26, 0]}>
        <group name="Null_1" position={[112.23, 120.77, 0]}>
          <group name="Null_2">
            <group name="Cloner">
              <mesh
                name="WireCube_1"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_1.geometry}
                material={nodes.WireCube_1.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_2"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_2.geometry}
                material={nodes.WireCube_2.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_3"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_3.geometry}
                material={nodes.WireCube_3.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_4"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_4.geometry}
                material={nodes.WireCube_4.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_5"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_5.geometry}
                material={nodes.WireCube_5.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_6"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_6.geometry}
                material={nodes.WireCube_6.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_7"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_7.geometry}
                material={nodes.WireCube_7.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_8"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_8.geometry}
                material={nodes.WireCube_8.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_9"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_9.geometry}
                material={nodes.WireCube_9.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_10"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_10.geometry}
                material={nodes.WireCube_10.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
              <mesh
                name="WireCube_0"
                castShadow
                receiveShadow
                geometry={nodes.WireCube_0.geometry}
                material={nodes.WireCube_0.material}
                > <meshPhysicalMaterial color="#1CE7C2" /></mesh>
            </group>
          </group>
        </group>
      </group>
      <PerspectiveCamera
          name="Camera"
          makeDefault={true}
          far={10000000000}
          near={0.01}
          fov={30}
          position={[0, 0, -527]}
          rotation={[Math.PI, 0, -Math.PI]}
        />
    </group>
  </group>
  );
}

useGLTF.preload("/jump2.gltf");